const jobs = [
  {
    name: '项目经理',
    icon: require('@/assets/images/joinus/icon-01.png'),
    open: false,
    duty: [
      '负责带领项目组完成系统开发；',
      '负责贯彻执行公司项目管理制度，参与过程改进和持续优化；',
      '协调项目资源，保证项目进度，监控项目质量，监控项目过程中的重大事项；',
      '参与指导编制项目计划，任务分解，需求分析，架构设计等主要活动；',
      '保持沟通，了解项目综合情况，对项目团队进行绩效管理。'
    ],
    requirement: [
      '本科以上学历，五年以上工作经验，其中2年以上项目管理经验；',
      '熟悉JAVA编程语言，并有过多个web项目开发及团队管理经验；',
      '熟练掌握struts2,hibernate,spring等常见的开源框架的项目搭建，调试，部署方面的实践经验；',
      '熟练使用oracle数据库，特别对sql语句，存储过程等的编写和调试；',
      '良好的协调、沟通表达能力，极积的服务意识；',
      'IT知识全面，熟悉linux,JAVA语言，ORACLE数据库，学习能力强，思路清晰；',
      '擅长拓展和管理客户关系，具有与客户方各个层面磋商的能力和经验；',
      '从事过支付、银行、电商等相关行业的web开发及管理经验优先考虑。'
    ]
  },
  {
    name: 'Java开发',
    icon: require('@/assets/images/joinus/icon-02.png'),
    open: false,
    duty: [
      '在项目团队进行Java研发；',
      '负责SaaS营销平台和电商平台相关产品JAVA开发与维护；',
      '根据公司发展需要，积极学习新的开发技术。'
    ],
    requirement: [
      '计算机相关专业，本科及以上学历；',
      '3年以上JAVA技术开发经验，累计1年以上电商开发经验；',
      '精通Java语言和主流开发框架，掌握Spring MVC、Dubbo,SpringBoot、MyBatis、数据库ORM框架技术，微服务架构，多线程，分布式技术，有独立系统框架设计能力；',
      '熟练掌握数据应用和设计，熟练使用主流数据库（ORACLE、Mysql）；',
      '熟悉Linux系统并能够用Nginx、Tomcat、Apache部署、配置web环境，掌握Git、SVN、Maven、Jenkins工具使用；',
      '熟悉zookeeper,redis,kafka等中间件，并在项目中开发和使用经验；',
      '具有良好的沟通协调能力，团队合作精神和执行力，能以业务目标为导向，能够承受工作压力和挑战；',
      '有全栈开发经验者优先。'
    ]
  },
  {
    name: '运营经理',
    icon: require('@/assets/images/joinus/icon-03.png'),
    open: false,
    duty: [
      '负责与银行对接，帮银行运营积分商城，微信公众号等对外互动渠道，以线上线下活动的方式和广大消费者产生联动。',
      '活动策划，撰写活动方案，宣传文案撰写，选品商品推荐，活动配置，活动总结，保障活动顺利上线，达到预期目标。',
      '对产品和技术有一定了解，能够结合业务思考产品和技术实现方案。',
      '负责日常订单监督，协助售后处理异常订单，协助对账回款。',
      '负责规划，执行年度，季度，月度营销计划，完成年度营销任务。',
      '综合考虑用户体验，利用营销工具提升用户参与度，增加用户粘性，并负责优化营销工具，模块及营销后台。'
    ],
    requirement: [
      '本科及以上学历，3年以上运营经验，有银行工作经历或银行营销策划经历优先。',
      '良好的沟通统筹协调能力，能协调调动多方推动工作进展，擅长ppt演讲。',
      '良好的文案策划能力，尤其擅长微信软文撰写，对数据敏感，有较好的数据分析能力。'
    ]
  },
  {
    name: '视觉设计师',
    icon: require('@/assets/images/joinus/icon-04.png'),
    open: false,
    duty: [
      '负责商城运营活动H5页面、专题页面、海报、活动小游戏工具等设计工作；',
      '根据产品定位，与产品经理进行良好沟通，协同前端及开发团队实现设计方案；',
      '负责商城等平台的活动视觉设计、构思创意、图片处理，具体包括：平台整体形象设计更新、商品详情制作、美化、视觉规划、促销活动等；',
      '定期根据节日促销，季节转换、平台促销等制作促销海报图片、专题页面设计、轮播图、海报、活动模板，具体包括：双11，双12等；',
      '沉淀页面、广告、活动形式形成规范及模板，并制定传承性文件；'
    ],
    requirement: [
      '本科以上学历，视觉传达，美术相关设计专业院校毕业，有2年以上平面偏电商设计相关工作经验；',
      '熟练使用Photoshop、AI等常用设计制作软件，具备良好的色彩把控能力；',
      '有扎实的美术功底和文字表达能力,有较强的审美能力，对网页风格美感有驾驭和品评能力；',
      '有线上商城平台运营类活动设计相关经验；',
      '善于沟通，执行力强，追求卓越，喜爱设计工作。',
      '提供设计作品或案例，有大型门户网站、电商类、运营类活动工作经验者工作经验者优先。'
    ]
  }
]
  
export default jobs