<template>
  <div>
    <banner :path="bannerPath"></banner>
    <my-tabs :tabs="tabs" :index="index"></my-tabs>
    <my-section title="人才理念" id="faith0">
      <div class="joinus-box1">
        <div>
          <p>优讯是一个富有理想和激情的团队，充满着追求创新的进取精神和蓬勃向上的朝气。公司聚集了一批善经营、懂管理、有专业知识、不断追求卓越的人才，优讯将持续奋斗，引领客户数智化经营。</p>
          <p>公司秉承"以人为本"的思想，倡导和营造一种”人人尊重与人人平等”的文化氛围，为每一位员工提供人性化的工作环境、完善的薪酬福利制度、可持续发展的机会和空间、努力创造公平竞争的环境。只要您付出思考和努力，虚心学习，不断进步，必会获得公正评价和丰厚回报。</p>
        </div>
        <img src="~@/assets/images/joinus/faith-01.jpg" />
      </div>
      <div class="joinus-box2">
        <div>
          <p>如果您是有志于投身客户经营数字化平台建设和运营事业的热血青年，优讯就是您充分施展才华、实现自我价值的舞台。我们真诚期待德才兼备的有志之士加盟优讯这一充满朝气与活力的团队。希望您与优讯一起跨出成功的步伐，一起迈向新的辉煌！</p>
        </div>
        <img src="~@/assets/images/joinus/faith-02.jpg" />
      </div>
    </my-section>
    <my-section title="职位招聘" id="faith1" class="job-list">
      <dl v-for="(item,i) in jobs" :key="i" class="job-item">
        <dt class="job-title">
          <img :src="item.icon" />
          <span>{{ item.name }}</span>
        </dt>
        <dd class="job-detail">
          <dl>
            <dt>岗位职责：</dt>
            <dd v-show="!item.open">
              <template v-for="(item2,i2) in item.duty">
                <p v-if="i2<2" :key="i2">{{ item2 }}</p>
              </template>
            </dd>
            <dd v-show="item.open">
              <p v-for="(item2,i2) in item.duty" :key="i2">{{ item2 }}</p>
            </dd>
          </dl>
          <dl v-show="item.open">
            <dt>岗位要求：</dt>
            <dd>
              <p v-for="(item3,i3) in item.requirement" :key="i3">{{ item3 }}</p>
            </dd>
          </dl>
          <p v-show="!item.open">……</p>
          <a href="javascript:;" :class="{'more' : item.open}" @click="item.open=!item.open">{{ item.open ? '收拢更多' : '查看更多' }}</a>
        </dd>
      </dl>
    </my-section>
    <side-code>
      <p>想来优讯？<br>微信扫码与<br>HR小姐姐来聊~</p>
    </side-code>
  </div>
</template>

<script>
import store from "@/store"
import jobs from "@/assets/js/jobs.js"
import { banner,myTabs,mySection, sideCode } from '@/components'
import bannerPath from '@/assets/images/joinus/banner.jpg'
export default {
  components: {
    banner,myTabs,
    mySection,
    sideCode
  },
  data () {
    return {
      jobs,
      bannerPath,
      tabs: ['人才理念','职位招聘']
    }
  },
  computed:{
    index(){
      return store.state.joinTab
    }
  },
  mounted () {
    
  },
  methods: {
    // tabchange(e){
    //   let section = document.querySelector("#faith" + e)
    //   if(section){
    //     window.scroll(0, section.offsetTop)
    //   }
    // }
  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/_variables.scss';
  @import '@/assets/scss/_extend.scss';
  @import '@/assets/scss/_mixin.scss';
  %joinus{
    @extend %c;
    width: 1200px;
    margin: 0 auto 30px;
    p{
      font-size: 16px;
      line-height: 30px;
      text-indent: 2em;
      &+p{
        margin-top: 30px;
      }
    }
  }
  .joinus-box1{
    @extend %joinus;
    >div{
      float: left;
      width: 600px;
    }
    img{
      @extend %fr;
    }
  }
  .joinus-box2{
    @extend %joinus;
    >div{
      float: right;
      width: 536px;
      padding-top: 100px;
    }
    img{
      @extend %fl;
    }
  }
  .job-list{
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .job-item{
    border: 20px solid #fff;
    border-radius: 10px;
    margin-bottom: 30px;
    background: #fff;
    box-shadow: 0 0 15px rgba(0,0,0,0.04);
    dl+dl{
      margin-top: 20px;
    }
  }
  .job-title{
    font-size: 0;
    margin-bottom: 15px;
    img{
      display: inline-block;
      width: 24px;
      height: 24px;
      vertical-align: middle;
      margin-right: 20px;
    }
    span{
      display: inline-block;
      @include lineHeight(32px);
      font-size: 24px;
      color: $color-primary - $color-primary;
      vertical-align: middle;
    }
  }
  .job-detail{
    @extend %pr;
    dt{
      @include lineHeight(32px);
      font-size: 18px;
      color: $color-primary - $color-primary;
    }
    dd{
      counter-reset: section;
      >p{
        &:before{
          position: absolute;
          top: 0;
          left: 0;
          counter-increment: section;
          content: counter(section)'、';
          display: block;          
        }
      }
    }
    p{
      position: relative;
      line-height: 32px;
      text-align: justify;
      font-size: 16px;
      color: $color-primary * 2;
      padding-left: 24px;
    }
    >a{
      position: absolute;
      right: 0;
      bottom: 0;
      display: block;
      @include lineHeight(32px);
      font-size: 16px;
      color: #2381ec;
      padding-right: 22px;
      &:after{
        position: absolute;
        top: 50%; right: 0;
        content: '';
        display: block;
        width: 12px;
        height: 8px;
        background: url('~@/assets/images/joinus/arrow.png');
        background-size: 100% 100%;
        margin-top: -4px;
        @extend %transition;
      }
      &.more{
        &:after{
          transform: rotate(180deg);
          transform-origin: center center;
        }
      }
    }
  }

  @media screen and (max-width:1200px) {
    .joinus-box1,.joinus-box2{
      width: auto;
      margin: 0 auto px2rem(15px);
      padding: 0 px2rem(15px);
      p{
        font-size: px2rem(14px);
        line-height: px2rem(24px);
        &+p{
          margin-top: px2rem(15px);
        }
      }
      >div{
        float: none;
        width: auto;
        padding: 0;
      }
      img{
        float: none;
        display: block;
        width: 90%;
        margin: px2rem(30px) auto;
      }
    }
    .job-list{
      width: auto;
      padding: 0 px2rem(15px) px2rem(10px);
    }
    .job-item{
      border: px2rem(15px) solid #fff;
      border-radius: px2rem(5px);
      margin-bottom: px2rem(15px);
      box-shadow: 0 0 px2rem(7.5px) rgba(0,0,0,0.04);
      dl+dl{
        margin-top: px2rem(10px);
      }
    }
    .job-title{
      display: flex;
      align-items: center;
      margin-bottom: px2rem(15px);
      img{
        display: block;
        width: px2rem(16px);
        height: px2rem(16px);
        margin-right: px2rem(10px);
      }
      span{
        display: block;
        flex: 1;
        height: px2rem(20px);
        line-height: px2rem(20px);
        font-size: px2rem(16px);
      }
    }
    .job-detail{
      dt{
        height: px2rem(24px);
        line-height: px2rem(24px);
        font-size: px2rem(14px);
        margin-bottom: px2rem(5px);
      }
      p{
        line-height: px2rem(24px);
        font-size: px2rem(12px);
        padding-left: px2rem(12px);
      }
      >a{
        height: px2rem(24px);
        line-height: px2rem(24px);
        font-size: px2rem(12px);
        padding-right: px2rem(12px);
        &:after{
          width: px2rem(8px);
          height: px2rem(6px);
          margin-top: px2rem(-3px);
        }
      }
    }
  }
</style>
